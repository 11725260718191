export const tokenGroups = {
  cultCoins: {
    name: "Cult Coins",
    displayTokens: "SPX69000, MOG, GIGA, HPOS10INU, RETARDIO",
    tokens: [
      "0xE0f63A424a4439cBE457D80E4f4b51aD25b2c56C",
      "0xaaeE1A9723aaDB7afA2810263653A34bA2C21C7a",
      "63LfDmNb3MQ8mw9MtZ2To9bEA2M71kZUUGq5tiJxcqj9",
      "0x72e4f9F808C49A2a61dE9C5896298920Dc4EEEa9",
      "6ogzHhzdrQr9Pgv6hZ2MNze7UrzBMAFyBBWUYp1Fhitx"
    ]
  },
  dogCoins: {
    name: "Dog Coins",
    displayTokens: "DOGE, SHIB, WIF, BONK, FLOKI, NEIRO, BABYDOGE, DOGS, NEIRO2, MYRO",
    tokens: [
        "EKpQGSJtjMFqKZ9KQanSqYXRcF8fBopzLHYxdM65zcjm",
        "DezXAZ8z7PnrnRJjz3wXBoRgixCa6xjnB7YaB1pPB263",
        "0xcf0C122c6b73ff809C693DB761e7BaeBe62b6a2E",
        "0x812Ba41e071C7b7fA4EBcFB62dF5F45f6fA853Ee",
        "0xc748673057861a797275CD8A068AbB95A902e8de",
        "EQCvxJy4eG8hyHBFsZ7eePxrRsUQSFE_jpptRAYBmcG_DOGS",
        "0xEE2a03Aa6Dacf51C18679C516ad5283d8E7C2637",
        "HhJpBhRRn4g56VsyLuT8DL5Bv31HkXqsrahTTUCZeZg4"
    ],
    coingeckoIds: ["dogecoin", "shiba-inu"]
  },
  catCoins: {
    name: "Cat Coins",
    displayTokens: "POPCAT, MEW, MICHI, SIMONS CAT, WEN, TOSHI, MINI, MANEKI, KEYCAT",
    tokens: [
        "7GCihgDB8fe6KNjn2MYtkzZcRjQy3t9GHdC8uHYmW2hr",
        "MEW1gQWJ3nEXg2qgERiKu7FAFj79PHvQVREQUzScPP5",
        "5mbK36SZ7J19An8jFochhQS4of8g6BwUjbeCSxBSoWdp",
        "0x6894CDe390a3f51155ea41Ed24a33A4827d3063D",
        "WENWENvqqNya429ubCdR81ZmD69brwQaaBYY6p3LCpk",
        "0xAC1Bd2486aAf3B5C0fc3Fd868558b082a531B2B4",
        "2JcXacFwt9mVAwBQ5nZkYwCyXQkRcdsYrDXn6hj22SbP",
        "25hAyBQfoDhfWx9ay6rarbgvWGwDdNqcHsXS3jQ3mTDJ",
        "0x9a26F5433671751C3276a065f57e5a02D2817973"
    ]
  },
  frogCoins: {
    name: "Frog Coins",
    displayTokens: "PEPE, BRETT, FWOG, APU, PEPECOIN, HOPPY, PEIPEI",
    tokens: [
        "0x6982508145454Ce325dDbE47a25d4ec3d2311933",
        "0x532f27101965dd16442E59d40670FaF5eBB142E4",
        "A8C3xuqscfmyLrte3VmTqrAq8kgMASius9AFNANwpump",
        "0x594DaaD7D77592a2b97b725A7AD59D7E188b5bFa",
        "0xA9E8aCf069C58aEc8825542845Fd754e41a9489A",
        "0x6E79B51959CF968d87826592f46f819F92466615",
        "0x3fFEea07a27Fab7ad1df5297fa75e77a43CB5790"
    ]
  },
  zooCoins: {
    name: "Zoo Coins",
    displayTokens: "MOODENG, PNUT, HIPPO(SUDENG), HARAMBE",
    tokens: [
        "ED5nyyWEzpPPiWimP8vYm7sD7TD3LAt3Q3gRTWHzPJBY",
        "2qEHjDLDLbuBgRYvsxhc5D6uDWAivNFZGan56P1tpump",
        "0x8993129d72e733985f7f1a00396cbd055bad6f817fee36576ce483c8bbb8b87b::sudeng::SUDENG",
        "Fch1oixTPri8zxBnmdCEADoJW2toyFHxqDZacQkwdvSP"
    ]
  },
  aiCoins: {
    name: "AI Coins",
    displayTokens: "GOAT, TURBO, CORGIAI, LUNA, AIDOGE, FARTCOIN, GROK, ZEREBRO, ACT",
    tokens: [
        "CzLSujWBLFsSjncfkh59rUFqvafWcY5tzedWJSuypump",
        "0xA35923162C49cF95e6BF26623385eb431ad920D3",
        "0x6b431B8a964BFcf28191b07c91189fF4403957D0",
        "0x55cD6469F597452B5A7536e2CD98fDE4c1247ee4",
        "0x09E18590E8f76b6Cf471b3cd75fE1A1a9D2B2c2b",
        "9BB6NFEcjBCtnNLFko2FqVQBq8HHM13kCyYcdQbgpump",
        "0x8390a1DA07E376ef7aDd4Be859BA74Fb83aA02D5",
        "8x5VqbHA8D7NkD52uNuS5nnt3PwA8pLD34ymskeSo2Wn",
        "GJAFwWjJ3vnTsrQVabjBVK2TYB1YtRCQXRDfDgUnpump"
    ]
  }
}; 