import React from 'react';
import styled from 'styled-components';

const ChartWrapper = styled.div`
  width: 100%;
  height: calc(100% - 80px);
`;

const TradingChartZoo = () => {
  React.useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://s3.tradingview.com/tv.js';
    script.async = true;
    document.body.appendChild(script);

    script.onload = () => {
      new window.TradingView.widget({
        autosize: true,
        symbol: "ORCA:MOODENGSOL_6BIUT5.USD+ORCA:PNUTSOL_86CM2R.USD+MEXC:HIPPOUSDT+RAYDIUM:HARAMBESOL_2BJKY9.USD", // Update with your zoo chart symbols
        interval: "D",
        timezone: "Etc/UTC",
        theme: "dark",
        style: "1",
        locale: "en",
        toolbar_bg: "#1e222d",
        enable_publishing: false,
        allow_symbol_change: true,
        container_id: "tradingview_chart_zoo",
        hide_side_toolbar: true,
        hide_top_toolbar: false,
        studies: [],
        disabled_features: [],
        enabled_features: [],
        height: "100%",
        width: "100%",
      });
    };

    return () => {
      if (script.parentNode) {
        script.parentNode.removeChild(script);
      }
    };
  }, []);

  return (
    <ChartWrapper>
      <div id="tradingview_chart_zoo" style={{ height: '100%', width: '100%' }} />
    </ChartWrapper>
  );
};

export default TradingChartZoo; 