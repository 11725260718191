import React from 'react';
import styled from 'styled-components';

const ChartWrapper = styled.div`
  width: 100%;
  height: calc(100% - 80px);
`;

const TradingChartFrogs = () => {
  React.useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://s3.tradingview.com/tv.js';
    script.async = true;
    document.body.appendChild(script);

    script.onload = () => {
      new window.TradingView.widget({
        autosize: true,
        symbol: "UNISWAP:PEPEWETH_A43FE1.USD+UNISWAP3BASE:BRETTWETH_BA3F94.USD+RAYDIUM:FWOGSOL_AB1EU2.USD+UNISWAP:APUWETH_5CED44.USD+UNISWAP:PEPECOINWETH_DDD237.USD+UNISWAP:HOPPYWETH_5C6919.USD+UNISWAP:PEIPEIWETH_BF1654.USD", // Update with your frogs chart symbols
        interval: "D",
        timezone: "Etc/UTC",
        theme: "dark",
        style: "1",
        locale: "en",
        toolbar_bg: "#1e222d",
        enable_publishing: false,
        allow_symbol_change: true,
        container_id: "tradingview_chart_frogs",
        hide_side_toolbar: true,
        hide_top_toolbar: false,
        studies: [],
        disabled_features: [],
        enabled_features: [],
        height: "100%",
        width: "100%",
      });
    };

    return () => {
      if (script.parentNode) {
        script.parentNode.removeChild(script);
      }
    };
  }, []);

  return (
    <ChartWrapper>
      <div id="tradingview_chart_frogs" style={{ height: '100%', width: '100%' }} />
    </ChartWrapper>
  );
};

export default TradingChartFrogs; 