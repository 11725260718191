import React from 'react';
import styled from 'styled-components';
import TradingChartCult from './TradingChartCult';
import TradingChartDogs from './TradingChartDogs';
import TradingChartCats from './TradingChartCats';
import TradingChartFrogs from './TradingChartFrogs';
import TradingChartZoo from './TradingChartZoo';
import TradingChartAi from './TradingChartAi';
import MarketInfo from './MarketInfo';
import { tokenGroups } from '../config/tokens';

const DashboardContainer = styled.div`
  background-color: #131722;
  min-height: 100vh;
  width: 100%;
  padding: 0 40px;
  padding-bottom: 60px;
  margin: 0;
  overflow: hidden;
`;

const Header = styled.header`
  padding: 20px;
  text-align: center;
  margin-bottom: 20px;
`;

const ChartContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  background-color: #131722;
  margin-bottom: 40px;
  &:last-child {
    margin-bottom: 0;
  }
`;

const ChartBox = styled.div`
  background-color: #1e222d;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.1);
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const ChartInnerContainer = styled.div`
  width: calc(100% - 40px);
  margin: 0 auto;
  height: 600px;
  overflow: hidden;
`;

const ChartTitle = styled.h2`
  color: white;
  text-align: center;
  font-size: 24px;
  font-weight: 500;
  margin: 0 0 20px 0;
`;

const Logo = styled.h1`
  font-family: 'Inter', sans-serif;
  font-size: 46px;
  font-weight: 700;
  color: #fff;
  margin: 0;
  display: inline-block;
  
  span {
    background: linear-gradient(135deg, #00C6FB 0%, #005BEA 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
  }
  
  &::after {
    content: '';
    display: block;
    width: 6px;
    height: 6px;
    background: #00C6FB;
    border-radius: 50%;
    margin-left: calc(100% + 4px);
    margin-top: -25px;
  }
`;

const Dashboard = () => {
  return (
    <DashboardContainer>
      <Header>
        <Logo>
          <span>indexy</span>
        </Logo>
      </Header>

      <ChartContainer>
        <ChartBox>
          <ChartTitle>{tokenGroups.dogCoins.name}</ChartTitle>
          <ChartInnerContainer>
            <TradingChartDogs />
          </ChartInnerContainer>
          <MarketInfo 
            tokens={tokenGroups.dogCoins.tokens} 
            groupName={tokenGroups.dogCoins.name}
            displayTokens={tokenGroups.dogCoins.displayTokens}
            coingeckoIds={tokenGroups.dogCoins.coingeckoIds}
          />
        </ChartBox>
      </ChartContainer>

      <ChartContainer>
        <ChartBox>
          <ChartTitle>{tokenGroups.frogCoins.name}</ChartTitle>
          <ChartInnerContainer>
            <TradingChartFrogs />
          </ChartInnerContainer>
          <MarketInfo 
            tokens={tokenGroups.frogCoins.tokens} 
            groupName={tokenGroups.frogCoins.name}
            displayTokens={tokenGroups.frogCoins.displayTokens}
          />
        </ChartBox>
      </ChartContainer>

      <ChartContainer>
        <ChartBox>
          <ChartTitle>{tokenGroups.catCoins.name}</ChartTitle>
          <ChartInnerContainer>
            <TradingChartCats />
          </ChartInnerContainer>
          <MarketInfo 
            tokens={tokenGroups.catCoins.tokens} 
            groupName={tokenGroups.catCoins.name}
            displayTokens={tokenGroups.catCoins.displayTokens}
          />
        </ChartBox>
      </ChartContainer>

      
      <ChartContainer>
        <ChartBox>
          <ChartTitle>{tokenGroups.cultCoins.name}</ChartTitle>
          <ChartInnerContainer>
            <TradingChartCult />
          </ChartInnerContainer>
          <MarketInfo 
            tokens={tokenGroups.cultCoins.tokens} 
            groupName={tokenGroups.cultCoins.name}
            displayTokens={tokenGroups.cultCoins.displayTokens}
          />
        </ChartBox>
      </ChartContainer>


      <ChartContainer>
        <ChartBox>
          <ChartTitle>{tokenGroups.aiCoins.name}</ChartTitle>
          <ChartInnerContainer>
            <TradingChartAi />
          </ChartInnerContainer>
          <MarketInfo 
            tokens={tokenGroups.aiCoins.tokens} 
            groupName={tokenGroups.aiCoins.name}
            displayTokens={tokenGroups.aiCoins.displayTokens}
          />
        </ChartBox>
      </ChartContainer>




      <ChartContainer>
        <ChartBox>
          <ChartTitle>{tokenGroups.zooCoins.name}</ChartTitle>
          <ChartInnerContainer>
            <TradingChartZoo />
          </ChartInnerContainer>
          <MarketInfo 
            tokens={tokenGroups.zooCoins.tokens} 
            groupName={tokenGroups.zooCoins.name}
            displayTokens={tokenGroups.zooCoins.displayTokens}
          />
        </ChartBox>
      </ChartContainer>


    </DashboardContainer>
  );
};

export default Dashboard;