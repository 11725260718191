import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

const MarketInfoContainer = styled.div`
  color: white;
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const InfoRow = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 18px;
`;

const Label = styled.span`
  color: #808a9d;
`;

const Value = styled.span`
  color: #fff;
`;

const TokenList = styled.div`
  font-size: 18px;
  color: #808a9d;
`;

const formatNumber = (num) => {
  if (num >= 1e9) {
    return `$${(num / 1e9).toFixed(2)}B`;
  }
  if (num >= 1e6) {
    return `$${(num / 1e6).toFixed(2)}M`;
  }
  if (num >= 1e3) {
    return `$${(num / 1e3).toFixed(2)}K`;
  }
  return `$${num.toFixed(2)}`;
};

const MarketInfo = ({ tokens, groupName, displayTokens, coingeckoIds }) => {
  const [totalFDV, setTotalFDV] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  const fetchDexScreenerFDV = async () => {
    try {
      const fdvPromises = tokens.map(async (token) => {
        const response = await fetch(`https://api.dexscreener.com/latest/dex/tokens/${token}`);
        const data = await response.json();
        const fdv = data.pairs?.[0]?.fdv;
        return fdv ? parseFloat(fdv) : 0;
      });

      const fdvValues = await Promise.all(fdvPromises);
      return fdvValues.reduce((sum, fdv) => sum + fdv, 0);
    } catch (error) {
      console.error('Error fetching DexScreener FDV:', error);
      return 0;
    }
  };

  const fetchCoingeckoFDV = async () => {
    if (!coingeckoIds || coingeckoIds.length === 0) return 0;
    
    try {
      const response = await fetch(
        `https://api.coingecko.com/api/v3/simple/price?ids=${coingeckoIds.join(',')}&vs_currencies=usd&include_market_cap=true`
      );
      const data = await response.json();
      
      const totalMarketCap = coingeckoIds.reduce((sum, id) => {
        return sum + (data[id]?.usd_market_cap || 0);
      }, 0);

      return totalMarketCap;
    } catch (error) {
      console.error('Error fetching Coingecko data:', error);
      return 0;
    }
  };

  const fetchAllData = async () => {
    const [dexScreenerTotal, coingeckoTotal] = await Promise.all([
      fetchDexScreenerFDV(),
      fetchCoingeckoFDV()
    ]);

    setTotalFDV(dexScreenerTotal + coingeckoTotal);
    setIsLoading(false);
  };

  useEffect(() => {
    fetchAllData();
    const interval = setInterval(fetchAllData, 120000); // Fetch every 2 minutes
    
    return () => clearInterval(interval);
  }, [tokens, coingeckoIds]);

  return (
    <MarketInfoContainer>
      <InfoRow>
        <Label>{groupName} Total Market Cap:</Label>
        <Value>
          {isLoading ? 'Loading...' : formatNumber(totalFDV)}
        </Value>
      </InfoRow>
      <TokenList>
        <Label>Tokens:</Label>{' '}
        <Value>{displayTokens}</Value>
      </TokenList>
    </MarketInfoContainer>
  );
};

export default MarketInfo; 