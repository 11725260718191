import React from 'react';
import styled from 'styled-components';

const ChartWrapper = styled.div`
  width: 100%;
  height: calc(100% - 80px);
`;

const TradingChartCats = () => {
  React.useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://s3.tradingview.com/tv.js';
    script.async = true;
    document.body.appendChild(script);

    script.onload = () => {
      new window.TradingView.widget({
        autosize: true,
        symbol: "RAYDIUM:POPCATSOL_FRHB8L.USD+RAYDIUM:MEWSOL_879F69.USD+RAYDIUM:MICHISOL_GH8ERS.USD+PANCAKESWAP:CATWBNB_6DF9AD.USD+ORCA:WENSOL_7BZZOP.USD+UNISWAP3BASE:TOSHIWETH_4B0AAF.USD+RAYDIUM:MINISOL_HYPXCA.USD+RAYDIUM:MANEKISOL_2APSSV.USD+UNISWAP3BASE:KEYCATWETH_D82403.USD", // Update with your cats chart symbols
        interval: "D",
        timezone: "Etc/UTC",
        theme: "dark",
        style: "1",
        locale: "en",
        toolbar_bg: "#1e222d",
        enable_publishing: false,
        allow_symbol_change: true,
        container_id: "tradingview_chart_cats",
        hide_side_toolbar: true,
        hide_top_toolbar: false,
        studies: [],
        disabled_features: [],
        enabled_features: [],
        height: "100%",
        width: "100%",
      });
    };

    return () => {
      if (script.parentNode) {
        script.parentNode.removeChild(script);
      }
    };
  }, []);

  return (
    <ChartWrapper>
      <div id="tradingview_chart_cats" style={{ height: '100%', width: '100%' }} />
    </ChartWrapper>
  );
};

export default TradingChartCats; 